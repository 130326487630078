<template>
  <button class="sk-btn sk-btn--default dirbook-btn"
          :disabled="disable"
          @click.stop.prevent="goToBook">{{ $gettext('Book now') }}</button>
</template>

<script>
  export default {
    props: {
      interUid: {
        type: String,
        default: ''
      },
      disable: {
        type: Boolean,
        default: false
      },
      isBlocked: Boolean
    },
    computed: {
      isPublicRoute() { return this.$route.name === 'PublicProfile'; }
    },
    methods: {
      goToBook() {
        const storageObject = {interpreterId: this.interUid};
        if (this.isBlocked) {
          this.$store.commit('ModalStore/setModal', {
            component: 'inter-blocked',
            data: {
              title: this.$gettext('Interpreter was blocked'),
              context: this,
              method: 'dirBookRedirect',
              params: [storageObject]
            }
          });
        } else {
          this.dirBookRedirect(storageObject);
        }
      },
      dirBookRedirect(storageObject) {
        const query = {interpreterid: storageObject.interpreterId};
        this.$router.push(this.$makeRoute({
          name: this.isPublicRoute ? 'SignIn' : 'BuyerPostInterpretation',
          query
        }));
      }
    }
  };
</script>

<style scoped>
.dirbook-btn {
  background-color: var(--color-primary) !important;
  color: white !important;
}
</style>
